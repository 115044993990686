<template>
  <div class="flex w-full">
    <label class="input input--checkbox">
      <input v-model="isChecked" type="checkbox" class="input__field" :disabled="disabled" @change="emitValue" />

      <div class="input__clone">
        <Icon v-if="isChecked" class="input__icon" icon="check" width="10px" height="10px" color="#000" />
      </div>

      <span class="ml-4 input__label">{{ label }}</span>
    </label>
  </div>
</template>

<script>
import { Icon } from "cavea-design-system";

export default {
  name: "InputCheckbox",

  components: {
    Icon,
  },

  props: {
    checked: {
      type: Boolean,
    },

    label: {
      type: String,
      default: "",
    },

    icon: {
      type: String,
      default: "",
    },

    prop: {
      type: [Object, Array, String, Number], // Any since i have no idea
      default: null,
    },

    item: {
      type: [Object, Array, String, Number], // Any since i have no idea
      default: null,
    },

    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isChecked: false,
    };
  },

  mounted() {
    this.isChecked = !!this.checked;
  },

  methods: {
    /**
     * @summary emit checkbox state
     * @emits boolean
     */
    emitValue() {
      this.$emit("toggle", {
        prop: this.prop,
        item: { ...this.item, value: this.isChecked },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/colors";
@import "../../styles/globals";
@import "../../styles/fonts";

.input {
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: row;
  cursor: pointer;

  &:not(:last-child) {
    margin-bottom: 0.5rem;
  }
}

.input__label {
  color: color(blue, light);
  font-size: 0.875rem;
  user-select: none;
  transition: var(--transition-out);
}

.input__clone {
  z-index: 1000;
  outline: 0;
  height: 1.25rem;
  display: flex;
  width: 1.25rem;
  border-radius: 3px;
  font-size: 0.875rem;
  border: 1px solid color(border, base);
  flex-grow: 0;
  color: color(white, base);
  font-weight: 600;
  background: color(white, off);
  font-family: $primary-font-stack;
  transition: var(--transition-in);
  align-items: center;
  justify-content: center;

  &::before {
    content: "";
    display: block;
    width: 50%;
    height: 50%;
    position: absolute;
    transform: scale(0);
    transition: var(--transition-in);
    transform-origin: center;
    border-radius: $border-radius;
    box-sizing: border-box;
  }

  > .icon {
    opacity: 0;
    visibility: hidden;
    transition: var(--transition-in);
  }
}

.input__field {
  opacity: 0;
  visibility: hidden;
  width: 0;

  &:checked {
    ~ .input__label {
      color: color(blue, light);
    }

    + .input__clone {
      &::before {
        transform: scale(1);
      }

      > .icon {
        color: color(signal, green);
        opacity: 1;
        visibility: visible;
      }
    }
  }
}
</style>
